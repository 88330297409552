import React from 'react';
import { Link } from 'react-router-dom';
import './WelcomePage.css';

const WelcomePage = () => {
  return (
    <div className="welcome-page">
      <header>
        <h1>Welcome to Broadview Strategy Group's</h1>
        <h2>PR Readiness Assessment Tool</h2>
      </header>
      <main>
        <section className="intro">
          <p>Are you ready to elevate your public relations strategy? Our assessment tool is designed to help you understand your current PR readiness and identify areas for improvement.</p>
          <p>This quick 5-minute assessment will provide valuable insights into your PR capabilities and offer personalized recommendations.</p>
        </section>
        <div className="video-container">
          <video autoPlay controls className="intro-video">
            <source src="/Broadview_AssessmentTool_IntroVideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <section className="instructions">
          <h3>Before you begin:</h3>
          <ol>
            <li>Watch the introductory video above for guidance on the assessment process.</li>
            <li>Ensure you have about 5 minutes of uninterrupted time to complete the assessment.</li>
            <li>Have basic information about your organization's PR activities ready.</li>
          </ol>
        </section>
        <Link to="/assessment" className="get-started-btn">Start Your PR Assessment</Link>
      </main>
    </div>
  );
};

export default WelcomePage;
