import React from 'react';
import { Link } from 'react-router-dom';

const ThankYou = () => {
  return (
    <div className="thank-you-page">
      <h1>Thank You for Completing the PR Readiness Assessment</h1>
      <p>We appreciate your time and input. Our team will review your responses and get back to you shortly with personalized insights and recommendations.</p>
      <p>If you have any questions or would like to discuss your results further, please don't hesitate to contact us.</p>
      <Link to="/" className="home-btn">Back to Home</Link>
    </div>
  );
};

export default ThankYou;
