import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import WelcomePage from './components/WelcomePage';
import PRReadinessTool from './components/PRReadinessTool';
import ThankYou from './components/ThankYou';  // Make sure this import is correct

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/assessment" element={<PRReadinessTool />} />
          <Route path="/thank-you" element={<ThankYou />} />
          {/* ... other routes */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
