import React, { useEffect } from 'react';
import './CalendlyWidget.css';

const CalendlyWidget = ({ onClose }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="calendly-modal-overlay" onClick={onClose}>
      <div className="calendly-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="calendly-modal-close" onClick={onClose} aria-label="Close">
          ×
        </button>
        <div 
          className="calendly-inline-widget" 
          data-url="https://calendly.com/jlaf/30min-consultation" 
          style={{ minWidth: '320px', height: '630px' }}
        />
      </div>
    </div>
  );
};

export default CalendlyWidget;